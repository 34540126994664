import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/elkuch/elkuch.png"
import elkuch1 from "../../../assets/images/portfolio_items/elkuch/elkuch1.png"
import elkuch2 from "../../../assets/images/portfolio_items/elkuch/elkuch2.png"
import elkuch3 from "../../../assets/images/portfolio_items/elkuch/elkuch3.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | Elkuch - Magnolia CMS Project'}
    description="Discover more about Elkuch's Magnolia website project: give a look at ICON's portfolio now!"
    canonical={'https://icon-worldwide.com/works/elkuch'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Elkuch, ICON Worldwide, magnolia CMS website portfolio" title=" ICON Worldwide portfolio, magnolia CMS, Elkuch "/>
            <div className="title"><h1>Elkuch<span></span></h1>
            <h2>Magnolia CMS project</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>Elkuch Group is a leading industrial manufacturing company in Europe. ICON designed and built the website using the Magnolia CMS.</p>
        <div className="portfolio-images">
            <img src={elkuch1} alt="Elkuch, ICON Worldwide, magnolia CMS website portfolio" title=" ICON Worldwide portfolio, magnolia CMS, Elkuch "/>
            <div></div>
            <img src={elkuch2} alt="Elkuch, ICON Worldwide, magnolia CMS website portfolio" title=" ICON Worldwide portfolio, magnolia CMS, Elkuch "/>
            <img src={elkuch3} alt="Elkuch, ICON Worldwide, magnolia CMS website portfolio" title=" ICON Worldwide portfolio, magnolia CMS, Elkuch "/>
        </div>
        <WorkFooter previous="iron-deficiency" next="crypto-valley-academy"/>
        </div>
    </div>
</div>